.zc-report-option {
  cursor: pointer;
}

.zc-report-option:hover {
  font-weight: bold;
}

ul.zc-reporting-breadcrumbs {
  padding: 0;
  margin: 0;
}
ul.zc-reporting-breadcrumbs li {
  display: inline;
}

ul.zc-reporting-breadcrumbs li::after {
  padding-left: .35rem;
  content: "/";
  padding-right: .35rem;
}

ul.zc-reporting-breadcrumbs li a {
  text-decoration: none;
}

ul.zc-reporting-breadcrumbs li a:hover {
  text-decoration: underline;
}