.formSection {
    margin: 0.8rem;
    padding: 0.8rem;
    border: 1px;    
    border-radius: 10px;
    border-style: solid;
}

i {
    cursor: pointer;
}

#selectIcons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
