.menu-item:has(> .fa-stack) {
  margin-left: -10px;
}

.menu-item:has(> .fa-stack) .menu-item-text {
  margin-left: -2px !important;
}

.menu-item .fa-stack {
  top: -2px;
}

.fa-delivery-discount .fa-tags {
  margin-left: -4px;
  margin-top: -2px;
  font-size: .5em;
  color: #000;
}


.fa-delivery-config .fa-cogs {
  margin-left: -4px;
  margin-top: -2px;
  font-size: .5em;
  color: #000;
}

.menu-item .fa-inverse {
  color: var(--zc-secondary-color) !important;
}